<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="d-block -mt-3 mb-3">
          To use your own domain/subdomain, you will need to update your DNS as follows:
          <br />
          <br />
          To use a dedicated top level domain ( e.g. yourdomain.com ), change it's A value to ours: 24.199.68.123
          <br />
          <br />
          To use a subdomain ( e.g. reviews.yourdomain.com ), create an A record under the domain, like so:
          <br />
          <br />
          <table class="table domain-table table-bordered">
            <tbody>
              <tr>
                <td>Host Target</td>
                <td>Your Subdomain Value</td>
              </tr>
              <tr>
                <td>reviews.yourdomain.com</td>
                <td>24.199.68.123</td>
              </tr>
            </tbody>
          </table>
          <br />
          You must point your domain to our servers prior to entering in your domain name. Please contact support@repuagency.com if the SSL doesn't work on your custom domain in an hour after you submit the request.
        </div>
        <button v-if="!domains.length" class="btn btn-dark-blue btn-sm" @click="showDomainForm = true">+ New Domain</button>
      </div>
    </div>
    <div v-if="user && domains.length" class="mt-3">
      <div v-for="(domain,i) in domains" :key="domain.id">
        <ValidationObserver
            v-slot="{ handleSubmit, invalid, touched }"
            :ref="`domainForm${i}`"
        >
          <form
              class="authentication-form"
              @submit.prevent="handleSubmit(() => updateDomain(domain,i))"
          >
            <span class="font-weight-bold">{{domain.domain}}</span><!--<button class="btn btn-link p-1" @click="showEditForm(domain)"><Feather type="edit" class="ml-2" /></button>-->
            <button class="btn btn-link p-1" @click="askDelete(domain)">
              <b-spinner v-if="loadingDomain" small />
              <Feather v-else type="trash" class="text-danger" />
            </button>
            <div class="form-group">
              <label :for="`favicon-${i}`" class="d-block">Favicon</label>
              <div v-if="domain.favicon_src" class="d-inline-block">
                <img :src="domain.favicon_src" alt="" class="mr-2" style="max-width: 80px" />
                <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteDomainImage(domain,'favicon')">
                  <b-spinner v-if="domain.deleting_image" small />
                  <i v-else class="uil uil-trash-alt" />
                </button>
              </div>
              <input :id="`favicon-${i}`" type="file" @change="setFavicon($event, domain)" />
            </div>
            <div class="form-group">
              <label :for="`logo-${i}`" class="d-block">Logo</label>
              <div v-if="domain.logo_src" class="d-inline-block">
                <img :src="domain.logo_src" alt="" class="mr-2" style="max-width: 200px" />
                <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteDomainImage(domain,'logo')">
                  <b-spinner v-if="domain.deleting_image" small />
                  <i v-else class="uil uil-trash-alt" />
                </button>
              </div>
              <input id="logo-${i}" type="file" name="logo" @change="setLogo($event, domain)" />
            </div>
            <b-form-group class="text-left">
              <b-button
                  variant="primary"
                  class="btn-dark-blue"
                  :disabled="domain.loading || (invalid && touched)"
                  type="submit"
              >
                <b-spinner v-if="domain.loading" small />
                <span v-else>Update</span>
              </b-button>
            </b-form-group>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <b-modal v-model="showDomainForm" :title="(form.domain_id !== null ? 'Edit' : 'Add') + ' Custom URL'" title-class="text-center" :hide-footer="true" size="lg" ok-variant="dark" @close="clearForm" >
      <ValidationObserver
          v-slot="{ handleSubmit, invalid, touched }"
          ref="domainForm"
      >
        <form
            class="authentication-form"
            @submit.prevent="handleSubmit(postDomain)"
        >
          <div class="row">
            <div class="col-12">
              <TextInput
                  v-model="form.domain"
                  name="name"
                  rules="required"
                  label="Custom URL"
                  type="text"
              />
            </div>
          </div>
          <b-form-group class="text-right">
            <b-button
                variant="primary"
                class="btn-dark-blue"
                :disabled="loadingDomain || (invalid && touched)"
                type="submit"
            >
              <b-spinner v-if="loadingDomain" small />
              <span v-else>Update</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Domain">
      <p v-if="selectedDomain"
      >Are you sure you want to delete domain "{{
          selectedDomain.domain
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
        >Cancel</b-button
        >
        <b-button variant="danger" @click="deleteDomain">
          <b-spinner v-if="loadingDomain" small />
          <span>Delete</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props:{
    hooktype:{
      type: [String,null],
      default:null
    }
  },
  data() {
    return {
      loadingDomain:false,
      showDomainForm: false,
      showDeleteModal:false,
      selectedDomain:{},
      user: {
        agency: {},
      },
      form:{
        domain_id:null,
        setting_form:'domain',
        domain:''
      },
      password: {
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed:{
    domains(){
      return this.user.agency.domains ? this.user.agency.domains : [];
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
  },
  methods: {
    deleteDomainImage(domain,type){
      domain.deleting_image = true;
      this.$store
          .dispatch('agency/editSettings', {
            domain_id:domain.id,
            setting_form:'domain_image_delete',
            image_type:type
          })
          .then((resp) => {
            domain.deleting_image = false;
            this.user.agency = resp.data.agency;
          })
          .catch((err) => {
            console.log(err);
            domain.deleting_image = false;
          })
    },
    setLogo(event,domain){
      domain.logo_sent = event.target.files[0]
    },
    setFavicon(event,domain){
      domain.favicon_sent = event.target.files[0]
    },
    askDelete(domain){
      this.showDeleteModal = true
      this.selectedDomain = domain
    },
    showEditForm(domain){
      this.showDomainForm = true;
      this.form.domain_id = domain.id
      this.form.domain = domain.domain
    },
    clearForm(){
      this.showDomainForm = false;
      this.form.domain_id = null;
      this.form.domain = '';
      this.selectedDomain = {};
    },
    deleteDomain(){
      this.showDeleteModal = false
      this.loadingDomain = true

      this.$store
          .dispatch('agency/editSettings', {domain_id:this.selectedDomain.id,setting_form:'domain_delete'})
          .then((resp) => {
            this.loadingDomain = false
            this.clearForm()
            this.user = resp.data;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.errors) {
              this.$refs.domainForm.setErrors(err.response.data.errors)
            }
            this.loadingDomain = false
          })
    },
    updateDomain(domain,i){
      let params = new FormData()
      params.append('icon', domain.favicon_sent);
      params.append('logo',domain.logo_sent)
      params.append('domainId',domain.id)
      domain.loading = true;
      this.$store
          .dispatch('agency/editDomainImages', params)
          .then((resp) => {
            console.log({
              resp:resp,
              domain:resp.data.domain
            });
            this.$set(this.user.agency.domains,i,resp.data.domain)
          })
          .catch((err) => {
            console.log(err);
            domain.loading = false;
          })
    },
    postDomain() {
      this.loadingDomain = true

      this.$store
          .dispatch('agency/editSettings', this.form)
          .then((resp) => {
            this.loadingDomain = false
            this.clearForm()
            this.user.agency.domains = resp.data.agency.domains;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.errors) {
              this.$refs.domainForm.setErrors(err.response.data.errors)
            }
            this.loadingDomain = false
          })
    },
  },
}
</script>
<style scoped lang="scss">
  table.domain-table{
    margin: 0;
    max-width: 400px;
    padding: 0;
  }
</style>
